import { Component } from 'react';

class DisableSSR extends Component {
  state = {
    render: false,
  };

  componentDidMount() {
    this.setState({
      render: true,
    });
  }

  render() {
    return this.state.render ? this.props.children : null;
  }
}

export default DisableSSR;
